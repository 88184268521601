"use client";
import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect, useState } from "react";

type Props = {
  // onSubmit(a: string): void;
};

const SearchContainer = styled(Box)`
  background-color: "white";
  padding: 5;
`;

const LandingSearch = (props: Props) => {
  console.log(props);
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const [searchValue, setSearchValue] = useState("");
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    if (searchParams && searchParams?.get("query")) {
      setSearchValue(searchParams.get("query")!);
    }
  }, [searchParams]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      router.push(`/jobs?query=${encodeURIComponent(searchValue)}`, {
        scroll: false,
      });
    }
  };

  return (
    (<SearchContainer
      mt={3}
      py={1}
      px={2}
      style={{
        backgroundColor: "white",
        display: "flex",
        zIndex: 2,
        borderRadius: "30px",
        alignItems: "center",
      }}
      gap={3}
    >
      <TextField
        fullWidth
        variant="standard"
        size="medium"
        value={searchValue}
        placeholder="Auxiliar contable, desarrollador, etc."
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={handleKeyPress}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" />
            </InputAdornment>
          ),
          endAdornment: (
            (<IconButton
              component={Link}
              href={pathname}
              scroll={false}
              onClick={() => setSearchValue("")}
              style={{
                cursor: "pointer",
                display: searchValue ? "block" : "none",
              }}
            >
              <Close color="primary" />
            </IconButton>)
            // <Link href={pathn ame} scroll={false}>
            //   <IconButton
            //     onClick={() => setSearchValue("")}
            //     style={{
            //       cursor: "pointer",
            //       display: searchValue ? "block" : "none",
            //     }}
            //   >
            //     <Close color="primary" />
            //   </IconButton>
            // </Link>
          ),
        }}
      />
      {upSm && (
        <Tooltip title="Pronto estaremos en mas ciudades" arrow>
          <TextField
            value="Bogota"
            disabled
            variant="standard"
            sx={{
              borderLeft: "1px solid #ccc",
              paddingLeft: 4,
              borderRight: "1px solid #ccc",
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      )}
      <Button
        component={Link}
        scroll={false}
        variant="contained"
        disabled={!searchValue}
        style={{ borderRadius: "30px" }}
        href={`/jobs?query=${encodeURIComponent(searchValue)}`}
      >
        Buscar
      </Button>
    </SearchContainer>)
  );
};

export default LandingSearch;
