"use client";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import React, { Suspense } from "react";
import LandingSearch from "./LandingSearch";

type Props = {};

// const Hero = styled(Box)`
//     /* background-image: url(${d}), */
//       width: '100%',
//       height: '100%',
// `;

const WebsiteLandingHero = (props: Props) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "relative",
        height: "600px",
        width: "100%",
      }}
    >
      <Image
        fill
        style={{
          objectFit: "cover",
          objectPosition: lessThanSmall ? "70% 10%" : "top",
        }}
        priority
        src={"/pexels-fauxels-3184405.webp"}
        alt={"Empleos en Colombia"}
      />
      <Grid
        container
        justifyContent="center"
        style={{
          position: "relative",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={8} lg={5}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            paragraph
            color="white"
          >
            ¡Busca tu siguiente reto laboral!
          </Typography>
          <Suspense fallback={<div>Loading...</div>}>
            <LandingSearch />
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WebsiteLandingHero;
