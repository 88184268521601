import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Chip,
  styled,
} from "@mui/material";
import {
  LocationOn,
  BusinessCenter,
  AttachMoney,
  Work,
} from "@mui/icons-material";
import Link from "next/link";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[10],
  },
}));

const CompanyIcon = styled(Work)(({ theme }) => ({
  width: 60,
  height: 60,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const JobInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

interface FeaturedJobCardProps {
  title: string;
  company: string;
  location: string;
  salary: string;
  jobType: string;
  postedDate: string;
}

export function LandingFeaturedJobCard({
  title,
  company,
  location,
  salary,
  jobType,
  postedDate,
}: FeaturedJobCardProps) {
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <CompanyIcon />
          <Typography variant="h6" component="div" align="center" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            {company}
          </Typography>
        </Box>
        <JobInfo>
          <LocationOn fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">{location}</Typography>
        </JobInfo>
        <JobInfo>
          <BusinessCenter fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">{jobType}</Typography>
        </JobInfo>
        <JobInfo>
          <AttachMoney fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">{salary}</Typography>
        </JobInfo>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2, pb: 2 }}>
        <Chip
          label={postedDate}
          color="secondary"
          size="small"
          sx={{ borderRadius: "4px" }}
        />
        <Button
          LinkComponent={Link}
          variant="contained"
          color="primary"
          size="small"
          href="/jobs/2"
          sx={{
            borderRadius: "20px",
            textTransform: "none",
            transition: "all 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          Apply Now
        </Button>
      </CardActions>
    </StyledCard>
  );
}
