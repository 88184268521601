"use client";
import { Container, Typography, Grid, Box } from "@mui/material";
import React from "react";
import { LandingFeaturedJobCard } from "./LandingFeaturedJobsCard";

type Props = {};

const featuredJobs = [
  {
    title: "Senior Software Engineer",
    company: "TechCorp",
    location: "San Francisco, CA",
    salary: "50000",
    jobType: "CDI",
    postedDate: "2022-01-01",
  },
  {
    title: "Product Manager",
    company: "InnovateCo",
    location: "New York, NY",
    salary: "50000",
    jobType: "CDI",
    postedDate: "2022-01-01",
  },
  {
    title: "UX Designer",
    company: "DesignHub",
    location: "London, UK",
    salary: "50000",
    jobType: "CDI",
    postedDate: "2022-01-01",
  },
  {
    title: "Data Scientist",
    company: "DataDriven",
    location: "Berlin, Germany",
    salary: "50000",
    jobType: "CDI",
    postedDate: "2022-01-01",
  },
];

const LandingFeaturedJobs = (props: Props) => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Ofertas recientes
      </Typography>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {featuredJobs.map((job, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <LandingFeaturedJobCard {...job} />

            {/* <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {job.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {job.company}
                </Typography>
                <Typography variant="body2">
                  <LocationOn sx={{ verticalAlign: "middle", mr: 0.5 }} />
                  {job.location}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            {/* <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {job.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {job.company}
                </Typography>
                <Typography variant="body2">
                  <LocationOn sx={{ verticalAlign: "middle", mr: 0.5 }} />
                  {job.location}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card> */}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LandingFeaturedJobs;
