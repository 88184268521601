"use client";
// import { Search, Send } from '@mui/icons-material'
// import { Grid, Stack, Chip, Typography, Card, Box } from '@mui/material'
// import React from 'react'

// type Props = {}

// const LandingProcess = (props: Props) => {
//   return (
//     <Grid item xs={10} md={8} lg={7}>
//     <Box pt={5}>
//       <Stack alignItems="center" pb={2}>
//         <Chip label="¿Cómo funciona?" color="secondary" />
//       </Stack>
//       <Typography variant="h3" align="center">
//         Pasos simples para encontrar el trabajo de tus sueños
//       </Typography>

//       <Grid
//         container
//         justifyContent="space-around"
//         rowGap={2}
//         rowSpacing={2}
//         spacing={2}
//         pt={6}
//       >
//         <Grid item xs={12} sm={6} md={5}>
//           <Card>
//             <Stack p={3} alignItems="center" spacing={3}>
//               <Search style={{ fontSize: "70px" }} color="primary" />
//               <Typography variant="h5" align="center">
//                 Busca empleo por palabras claves
//               </Typography>
//               <Typography variant="body1" textAlign="center">
//                 Encuentra empleo de forma rápida y precisa al ingresar las
//                 palabras clave que mejor se adapten a tus habilidades y
//                 aspiraciones. Deja que nuestra herramienta te conecte con
//                 las oportunidades de trabajo que realmente te interesan.
//                 ¡Sé el dueño de tu futuro profesional y comienza a buscar
//                 empleo de manera inteligente hoy mismo!
//               </Typography>
//             </Stack>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6} md={5}>
//           <Card>
//             <Stack p={3} alignItems="center" spacing={3}>
//               <Send style={{ fontSize: "70px" }} color="primary" />
//               <Typography variant="h5" align="center">
//                 Postula a tus ofertas preferidas
//               </Typography>
//               <Typography variant="body1" textAlign="center">
//                 Nuestro proceso de postulación en dos clics es rápido y
//                 sin complicaciones. Olvídate de formularios largos y
//                 tediosos. Hacemos que la postulación sea eficiente para
//                 que puedas dedicar más tiempo a lo que realmente importa:
//                 tu futuro profesional. ¡Postúlate en un abrir y cerrar de
//                 ojos y da el primer paso hacia tu nueva carrera!
//               </Typography>
//             </Stack>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   </Grid>
//   )
// }

// export default LandingProcess

import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { Search, Assessment, Send } from "@mui/icons-material";

const steps = [
  {
    icon: <Search fontSize="large" />,
    title: "Busca por título de trabajo",
    description:
      "Utiliza nuestra potente herramienta de búsqueda para encontrar oportunidades que se ajusten a tus habilidades y experiencia.",
  },
  {
    icon: <Assessment fontSize="large" />,
    title: "Evalúa las ofertas",
    description:
      "Compara las diferentes ofertas de trabajo y elige la que mejor se adapte a tus necesidades y objetivos profesionales.",
  },
  {
    icon: <Send fontSize="large" />,
    title: "Envía tu solicitud",
    description:
      "Completa un sencillo formulario de solicitud y envía tu currículum con solo unos clics.",
  },
];

export default function LandingProcess() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Encuentra tu trabajo ideal en tres simples pasos
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          paragraph
        >
          Nuestro proceso simplificado hace que buscar trabajo sea fácil y
          eficiente.
        </Typography>
        {/* <Grid container spacing={4} sx={{ mt: 4 }}> */}
        <Stack
          gap={3}
          flexDirection={"row"}
          pt={5}
          flexWrap="wrap"
          alignContent="center"
          justifyContent="center"
        >
          {steps.map((step, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                height: "100%",
                width: "300px",
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "4px",
                  backgroundColor: "primary.main",
                },
              }}
            >
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  bgcolor: "primary.main",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                  color: "primary.contrastText",
                }}
              >
                {step.icon}
              </Box>
              <Typography variant="h6" align="center" gutterBottom>
                {step.title}
              </Typography>
              <Typography variant="body2" align="center" color="text.secondary">
                {step.description}
              </Typography>
              {!isMobile && index < steps.length - 1 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "30%",
                    right: -30,
                    width: 60,
                    height: 2,
                    bgcolor: "primary.main",
                    zIndex: 1,
                  }}
                />
              )}
            </Paper>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
